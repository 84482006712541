
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Publication } from './types'
import { parseReview } from '@/utils/utils'
import ArticleSourceModal from '@/components/ArticleSourceModal.vue'
import InternalLinks from '@/components/InternalLinks.vue'
import CollapsibleReview from '@/components/search-results/publications-results/CollapsibleReview.vue'
import BibIssueFormat from '@/components/bib-formatting/BibIssueFormat.vue'

@Component({
  components: {
    ArticleSourceModal,
    InternalLinks,
    CollapsibleReview,
    BibIssueFormat,
  },
})
export default class GenericFormat extends Vue {
  @Prop() item!: Publication
  @Prop({ default: false }) internalAccess!: boolean
  @Prop({ default: false }) unsubbed!: boolean

  showArticleModal = false

  //
  // COMPONENT PROPERTIES
  //

  get formattedMRNumber() {
    // Add leading zeros if needed
    const mrNumLength = 7
    const leadingZeros = mrNumLength - this.item.mrnumber.toString().length
    return '0'.repeat(leadingZeros) + this.item.mrnumber
  }

  get articleRoute() {
    return {
      name: 'ArticlePage',
      query: {
        // mr: this.item.mrnumber.toString(),
        mr: this.formattedMRNumber,
      },
    }
  }

  get isPrelim() {
    return this.item.itemType.toLowerCase() === 'prelim'
  }

  get hasArticle() {
    return (this.item.articleUrl && this.item.articleUrl !== '') || (this.item.openURL && this.item.openURL.targetLink.length > 0)
  }

  get title() {
    return parseReview(this.item.titles.title)
  }

  get translatedTitle() {
    return parseReview(this.item.titles.translatedTitle)
  }

  get mscRoute() {
    const route = {
      name: 'PublicationsSearch',
      query: {
        query: 'pc:"' + this.item.primaryClass.code + '"',
      },
    }
    return route
  }

  get reviewers() {
    if (this.item.prePubl &&
      this.item.prePubl.reviewer &&
      this.item.prePubl.reviewer.reviewers) {
      return this.item.prePubl.reviewer.reviewers
    } else {
      if (this.item.reviewer &&
      this.item.reviewer.reviewers
      ) {
        return this.item.reviewer.reviewers
      } else {
        return []
      }
    }
  }

  //
  // METHODS
  //

  toggleShowingFull() {
    // Leftover code from displaying reviews in the search results. Might return to this idea later if it can meet accessibility concerns (MathJax off screen could still get tab focus)
    const reviewEl = this.$refs.reviewDiv as HTMLElement
    if (reviewEl.classList.contains('fade-bottom')) {
      reviewEl.classList.remove('fade-bottom')
    } else {
      reviewEl.classList.add('fade-bottom')
    }
  }

  updateScrollInfo() {
    alert('HERE')
  }
}
