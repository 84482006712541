
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { PublicationFormat, UserList } from '@/global-types'
import { PublicationsAPI, ArticlesAPI, UserListsAPI } from '@/api'
import { trackArticleCounter5 } from '@/counter5-tracker'
import { UserListModal } from '@/components/user-lists'

interface userListSelectType {
  name: string,
  value: string
}

@Component({
  components: {
    UserListModal,
  },
})
export default class ResultsCitationsComponent extends Vue {
  @Prop({ required: true }) selected! : number[]
  @Prop({ required: true }) batchOpened! : number[]
  @Prop({ required: true }) pageSize! : number
  @Prop({ required: true }) hits! : number
  @Prop({ default: false }) unsubbed! : boolean
  @Prop({ type: String }) sortBy!: string
  @Prop({ type: String }) order!: string

  @Ref() readonly userListModal!: UserListModal

  showSubscriptionModal = false
  localCheckAll = false
  checkboxStatus = [false]
  showCitationModal = false
  dismissCountDown = 0
  fetchingCitation = false
  citationType : PublicationFormat = PublicationFormat.BIB
  citationText = ''
  citationOptions = [
    // { name: 'Plain Text', value: PublicationFormat.PLAIN },
    { name: 'AMSRef', value: PublicationFormat.AMS },
    { name: 'BibTeX', value: PublicationFormat.BIB },
    { name: 'EndNote', value: PublicationFormat.END },
    { name: 'TeX', value: PublicationFormat.TEX },
  ]

  userListModalOpen = false
  userListSelected = ''
  userListOptions:userListSelectType[] = []

  mounted() {
    this.loadLocalStorage()
    UserListsAPI.setupEventListener(this.userListsStorageUpdated)
    // The API might have been called before the listener got setup
    this.userListsStorageUpdated()
  }

  loadLocalStorage() {
    const savedFormat = localStorage.getItem('preferred_citation_format')
    if (savedFormat) {
      this.citationType = savedFormat
    }
  }

  saveLocalStorage() {
    localStorage.setItem('preferred_citation_format', this.citationType)
  }

  userListsStorageUpdated() {
    this.updateUserListOptions()
    // Unsure if other things need to happen when the lists are updated
  }

  updateUserListOptions() {
    const userLists: UserList[] = UserListsAPI.pullFromLocalStorage()
    // Rebuild the list, then replace everything
    const newUserListOptions:userListSelectType[] = []
    for (const i in userLists) {
      const list: UserList = userLists[i]
      const listName = '' + list.label + ' (' + list.mrNumbers.length + ')'
      newUserListOptions.push({ name: listName, value: list.id! })
    }
    this.userListOptions.splice(0, this.userListOptions.length, ...newUserListOptions)
    if (this.userListOptions.length > 0) {
      this.userListSelected = this.userListOptions[0].value
    } else {
      this.userListSelected = ''
    }
  }

  addToUserList() {
    const userLists: UserList[] = UserListsAPI.pullFromLocalStorage()
    const currentMrNumbers = userLists.filter(x => x.id === this.userListSelected)[0].mrNumbers
    const newMrNumbers = this.selected.filter(x => !currentMrNumbers.includes(x))
    UserListsAPI.updateList(this.userListSelected, undefined, [...currentMrNumbers, ...newMrNumbers])
  }

  removeFromUserList() {
    const userLists: UserList[] = UserListsAPI.pullFromLocalStorage()
    const newMrNumbers = userLists.filter(x => x.id === this.userListSelected)[0].mrNumbers.filter(x => !this.selected.includes(x))
    UserListsAPI.updateList(this.userListSelected, undefined, newMrNumbers)
  }

  changeListTest() {
    UserListsAPI.getLists()
    this.updateUserListOptions()
  }

  @Watch('selected')
  onSelectedPapersChange() {
    this.localCheckAll = (this.selected.length === Math.min(this.pageSize, this.hits)) && this.selected.length > 0
  }

  @Watch('fetchingCitation')
  async onFetchingCitationChange() {
    if (this.fetchingCitation) {
      // track (send to liblinx)
      const articles = await ArticlesAPI.getArticles(this.selected, this.selected.length)

      articles.forEach(article => {
        if (!this.unsubbed) {
          // keep the review field empty to track and investigation instead of a request
          trackArticleCounter5({ ...article, review: '' })
        }
      })
    }
  }

  async getCitations(format: PublicationFormat) {
    // Called when the select box changes
    if (format === undefined) {
      format = this.citationType
    }
    this.saveLocalStorage()
    // Look at which MR numbers are selected
    this.fetchingCitation = true
    this.citationText = ''
    const results = await PublicationsAPI.getPublicationFormat(
      [format],
      this.selected,
      this.sortBy,
      this.order,
    )

    this.citationText = results.map(x => x[this.citationType]).join('\n\n')
    this.fetchingCitation = false
    return results
  }

  copyCitation() {
    const copyText = document.getElementById('citationText') as HTMLInputElement
    if (copyText) {
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */
      navigator.clipboard.writeText(copyText.value)
      copyText.setSelectionRange(0, 0) /* Clear the highlighting from the text */

      // Notify that the text was copied
      this.dismissCountDown = 3
    }
  }

  countDownChanged(dismissCountDown: number): void {
    this.dismissCountDown = dismissCountDown
  }

  // async copyCitations() {
  //   if (this.citationText) {
  //     // Duplicated to keep this from being changed while waiting for response
  //     const selected = this.selected
  //     const citationType = this.citationType
  //     const citationData = await this.getCitations(citationType)
  //     let citationText = ''
  //     for (const i in selected) {
  //       const paperData = citationData.filter(x => x.paperId === selected[i])[0]
  //       citationText += paperData[citationType] + '\n'
  //     }
  //     // Copy to clipboard
  //     this.citationText = citationText
  //   }
  // }

  async displayCitations() {
    this.showCitationModal = true
    // Request the data
    await this.getCitations(this.citationType)
  }

  setAllCheckboxes() {
    // this.$emit('set-all-checkboxes', this.updateCheckAll)
    this.$emit('set-all-checkboxes', this.localCheckAll)
  }

  showUnsubbedModal() {
    this.showSubscriptionModal = true
  }

  test(e: Event) {
    e.preventDefault()
    e.stopPropagation()
  }
}
